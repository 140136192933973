import { BasicResult } from "../../basic-result";
import { BasicAuditReturn } from "./basic-audit-return";

export class BasicAuditResult extends BasicResult {
    public Audits: BasicAuditReturn[] = [];
    constructor(values: Object = {}) {
        super();
        if (values.hasOwnProperty('Audits')) {
            this.Audits = [];
            ((values as BasicAuditResult)['Audits'] as BasicAuditReturn[]).forEach(element => {
                this.Audits.push(new BasicAuditReturn(element));
            });
        }
    }
}