import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuditResultQuestion } from 'src/app/core-module/models/Audits/AuditResults/audit-result-question';
import { UtilService } from 'src/app/core-module/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-audit-result-detail-question',
  templateUrl: './audit-result-detail-question.component.html',
  styleUrl: './audit-result-detail-question.component.scss'
})
export class AuditResultDetailQuestionComponent {
  @Input() question:AuditResultQuestion = new AuditResultQuestion();

  public expandedAttachmentPanel:boolean = false;
  public expandedPhotoPanel:boolean = false;
  public expandedActionPanel:boolean = false;
  public isBluebird:boolean = false;

  constructor(private util:UtilService, private router:Router){
    this.isBluebird = environment.blueBird;
  }

  ngOnInit(){
    this.reorderActionsByDate();
  }

  reorderActionsByDate(){
    this.question.Actions = this.question.Actions.sort((a,b)=>
      { if(!a.Resolved && b.Resolved){
        return -1 ;
      } 
      if(!a.Resolved && !b.Resolved && a.ActionDeadline<b.ActionDeadline){
        return -1;
      }
      if(a.Resolved && b.Resolved && a.ResolvedDate<b.ResolvedDate){
        return -1;
      }
        //a !b
      return 1})
    ;
  }
  isPastDate(deadline:Date){
    return new Date(deadline) < new Date()
  }
  switchAttachmentPanel(){
    if(this.question.QuestionAttachments.length==0){
      return;
    }
    this.expandedAttachmentPanel =! this.expandedAttachmentPanel;
  }
  switchPhotoPanel(){
    if(this.question.Photos.length==0){
      return;
    }else{
      this.expandedPhotoPanel = !this.expandedPhotoPanel;
    }
  } 
  switchActionPanel(){
    if(this.question.Actions.length>0){
      this.expandedActionPanel= !this.expandedActionPanel;
    }
  }
  isCompliant():boolean{
  if(this.question.ComplianceLimit<=0 || this.question.ComplianceLimit<this.question.Score || this.question.NASelected || this.question.QuestionAnswered == false){
      return true;
    }
    return false;
  }
  isResolved():boolean{
    if(this.isCompliant()){
      return true;
    }
    var isResolved = true;
    this.question.Actions.forEach(e=>{
      if(!e.Resolved){
        isResolved = false;
      }
    })  
    return isResolved;
  }
  humanDate(date:Date):string{
    return this.util.humanDate(date);
  }
  shortDate(date:Date):string{
    return this.util.shortHumanDate(date);
  }
  downloadAttachment(GUID:string){
    window.open(`${environment.apiUrl}audits/getattachment/`+GUID,'_blank')
  }
  detailAction(actionID:number){
    this.router.navigateByUrl('action',{state: {"ActionID" : actionID,"Result":true}});
  }
  photoSrc(GUID:string):string{
    return `${environment.apiUrl}audits/getphoto/`+GUID;
  }
  filenameReduction(filename:string):string{
    if(filename.length>40){
      return filename.substring(0,19) + "..." + filename.substring(filename.length - 18);
    }else{
      return filename
    }
  }
  stringReduction(text:string,characterLength:number){
    if(text.length>characterLength){
      return text.substring(0,characterLength) + "..."
    }
    return text;
  }
  allActionsResolved():boolean{
    return this.question.Actions.every(a => a.Resolved);
  }
}
