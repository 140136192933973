import { DDL } from "../../ddl";

export class BasicAuditReturn {
    public AuditID: number = -1;
    public AuditName: string = "";
    public AuditType: DDL = new DDL();
    public Display:boolean = true;
    constructor(values: Object = {}) {
        Object.assign(this, values);
        if (values.hasOwnProperty('AuditType')) {
            this.AuditType = new DDL((values as BasicAuditReturn)['AuditType'] as DDL);
        }
    }
}