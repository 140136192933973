import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from 'src/app/root-module/app-routing.module';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from '../home/home.component';
import { SecureComponent } from 'src/app/shared-module/layout/secure/secure.component';
import { OwlDateTimeComponent, OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { MenuComponent } from '../menu/menu.component';
import { StartAuditComponent } from '../start-audit/start-audit.component';
import { SelectLocationComponent } from '../select-location/select-location.component';
import { AuditMainComponent } from '../audit-main/audit-main.component';
import { TestpageComponent } from '../testpage/testpage.component';
import { QuestionHolderComponent } from '../question-holder/question-holder.component';
import { DropDownComponent } from '../question-templates/drop-down/drop-down.component';
import { RadioButtonComponent } from '../question-templates/radio-button/radio-button.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { TextEntryComponent } from '../question-templates/text-entry/text-entry.component';
import { DateTimeComponent, HashDirective } from '../question-templates/date-time/date-time.component';
import { DateOnlyComponent } from '../question-templates/date-only/date-only.component';
import { CanvasAreaComponent } from '../question-templates/canvas-area/canvas-area.component';
import { AuditMainHeaderComponent } from '../audit-main/audit-main-header/audit-main-header.component';
import { SignaturePadModule } from 'angular-signature-pad-v2';
import { QuestionActionComponent } from '../question-holder/question-action/question-action.component';
import { QuestionActionUseractionComponent } from '../question-holder/question-action/question-action-useraction/question-action-useraction.component';
import { MultiSelectComponent } from '../question-templates/multi-select/multi-select.component';
import { AuditContentsComponent } from '../audit-main/audit-contents/audit-contents.component';
import { SettingsComponent } from '../settings/settings.component';
import { ManageAuditsComponent } from '../settings/manageaudits/manageaudits.component';
import { SelectAuditEditComponent } from '../settings/manageaudits/select-audit-edit/select-audit-edit.component';
import { EditAuditComponent } from '../settings/manageaudits/edit-audit/edit-audit.component';
import { ToggleSwitchComponent } from 'src/app/shared-module/components/toggle-switch/toggle-switch.component';
import { EntryInfoToggleComponent } from '../settings/manageaudits/edit-audit/entry-info-toggle/entry-info-toggle.component';
import { EditMainInfoComponent } from '../settings/manageaudits/edit-audit/edit-main-info/edit-main-info.component';
import { EditEntryOptionsComponent } from '../settings/manageaudits/edit-audit/edit-entry-options/edit-entry-options.component';
import { EditQuestionsComponent } from '../settings/manageaudits/edit-audit/edit-questions/edit-questions.component';
import { EditQuestionHeadingComponent } from '../settings/manageaudits/edit-audit/edit-questions/edit-question-heading/edit-question-heading.component';
import { TextBoxEntryComponent } from 'src/app/shared-module/components/text-box-entry/text-box-entry.component';
import { DropdownEntryComponent } from 'src/app/shared-module/components/dropdown-entry/dropdown-entry.component';
import { EditQuestionBasicComponent } from '../settings/manageaudits/edit-audit/edit-questions/edit-question-basic/edit-question-basic.component';
import { EditQuestionSelectionsComponent } from '../settings/manageaudits/edit-audit/edit-questions/edit-question-selections/edit-question-selections.component';
import { NumberBoxEntryComponent } from 'src/app/shared-module/components/number-box-entry/number-box-entry.component';
import { EditWorkflowComponent } from '../settings/manageaudits/edit-audit/edit-workflow/edit-workflow.component';
import { IncompletePanelComponent } from '../home/incomplete-panel/incomplete-panel.component';
import { ActionPanelComponent } from '../home/action-panel/action-panel.component';
import { ActionsComponent } from '../actions/actions.component';
import { ActionDetailComponent } from '../action-detail/action-detail.component';
import { NewActionQuickComponent } from '../action-detail/new-action-quick/new-action-quick.component';
import { ToastrComponent } from 'src/app/shared-module/components/toastr/toastr.component';
import { ToastrHolderComponent } from 'src/app/shared-module/components/toastr-holder/toastr-holder.component';
import { WebcamModule } from 'ngx-webcam';
import { QuestionPhotoComponent } from '../question-holder/question-photo/question-photo.component';
import { QuestionAttachmentComponent } from '../question-holder/question-attachment/question-attachment.component';
import { DiaryComponent } from '../diary/diary.component';
import { AddDiaryEventComponent } from '../diary/add-diary-event/add-diary-event.component';
import { ResultsHomeComponent } from '../results-home/results-home.component';
import { AuditResultComponent } from '../results-home/audit-result/audit-result.component';
import { AuditResultDetailComponent } from '../results-home/audit-result-detail/audit-result-detail.component';
import { AuditResultDetailQuestionComponent } from '../results-home/audit-result-detail/audit-result-detail-question/audit-result-detail-question.component';
import { CalendarComponent } from '../diary/calendar/calendar.component';
import { CalendarCellComponent } from '../diary/calendar/calendar-cell/calendar-cell.component';
import { DayDetailComponent } from '../diary/day-detail/day-detail.component';
import { ScheduledAuditComponent } from '../diary/day-detail/scheduled-audit/scheduled-audit.component';
import { NarrativeComponent } from '../question-templates/narrative/narrative.component';
import { ManageusersComponent } from '../settings/manageusers/manageusers.component';
import { ExistingUsersComponent } from '../settings/manageusers/existing-users/existing-users.component';
import {CdkTableModule} from '@angular/cdk/table';
import { GroupManagementComponent } from '../settings/manageusers/group-management/group-management.component';
import { NewUserComponent } from '../settings/manageusers/new-user/new-user.component';
import { AssignAuditsComponent } from '../settings/manageaudits/assign-audits/assign-audits.component';
import { AssignLocationsComponent } from '../settings/managelocations/assign-locations/assign-locations.component';
import { ManagelocationsComponent } from '../settings/managelocations/managelocations.component';
import { EditLocationComponent } from '../settings/managelocations/edit-location/edit-location.component';
import { MenuHolderComponent } from '../settings/menu-holder/menu-holder.component';
import { CurrentUsersComponent } from '../settings/manageusers/current-users/current-users.component';
import { UserProfileComponent } from '../settings/manageusers/user-profile/user-profile.component';
import { UserAuditsComponent } from '../settings/manageusers/user-audits/user-audits.component';
import { AssignLocationAuditsComponent } from '../settings/managelocations/assign-location-audits/assign-location-audits.component';
import { AuditlocationsComponent } from '../settings/manageaudits/auditlocations/auditlocations.component';
import { AuditusersComponent } from '../settings/manageaudits/auditusers/auditusers.component';

@NgModule({
    declarations: [
        SecureComponent,
        HomeComponent,
        MenuComponent,
        StartAuditComponent,
        SelectLocationComponent,
        AuditMainComponent,
        TestpageComponent,
        QuestionHolderComponent,
        DropDownComponent,
        RadioButtonComponent,
        TextEntryComponent,
        DateTimeComponent,
        DateOnlyComponent,
        CanvasAreaComponent,
        AuditMainHeaderComponent,
        QuestionActionComponent,
        QuestionActionUseractionComponent,
        MultiSelectComponent,
        AuditContentsComponent,
        SettingsComponent,
        ManageAuditsComponent,
        SelectAuditEditComponent,
        EditAuditComponent,
        ToggleSwitchComponent,
        EntryInfoToggleComponent,
        EditMainInfoComponent,
        EditEntryOptionsComponent,
        EditQuestionsComponent,
        EditQuestionHeadingComponent,
        TextBoxEntryComponent,
        NumberBoxEntryComponent,
        DropdownEntryComponent,
        EditQuestionBasicComponent,
        EditQuestionSelectionsComponent,
        IncompletePanelComponent,
        HashDirective,
        ActionPanelComponent,
        EditWorkflowComponent,
        ActionsComponent,
        ActionDetailComponent,
        NewActionQuickComponent,
        QuestionPhotoComponent,
        QuestionAttachmentComponent,
        ResultsHomeComponent,
        AuditResultComponent,
        DiaryComponent,
        AddDiaryEventComponent,
        ResultsHomeComponent,
        AuditResultDetailComponent,
        AuditResultDetailQuestionComponent,
        CalendarComponent,
        CalendarCellComponent,
        DayDetailComponent,
        ScheduledAuditComponent,
        NarrativeComponent,
        ManageusersComponent,
        ExistingUsersComponent,
        GroupManagementComponent,
        NewUserComponent,
        AssignAuditsComponent,
        AssignLocationsComponent,
        ManagelocationsComponent,
        EditLocationComponent,
        MenuHolderComponent,
        CurrentUsersComponent,
        UserProfileComponent,
        UserAuditsComponent,
        AssignLocationAuditsComponent,
        AuditlocationsComponent,
        AuditusersComponent
    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        FormsModule,
        OwlDateTimeModule,
        BrowserAnimationsModule,
        SignaturePadModule,
        WebcamModule,
        CdkTableModule
    ]
})
  export class SecureComponentsModule { }