import { Component, Input } from '@angular/core';
import { AuditResultDetail } from 'src/app/core-module/models/Audits/AuditResults/audit-result-detail';
import { UtilService } from 'src/app/core-module/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-audit-result-detail',
  templateUrl: './audit-result-detail.component.html',
  styleUrl: './audit-result-detail.component.scss'
})
export class AuditResultDetailComponent {
  @Input() auditResultDetail:AuditResultDetail = new AuditResultDetail();

  public isBluebird:boolean = false;
  constructor(private utilService:UtilService){
    this.isBluebird = environment.blueBird;
  }

  calcPercent():number{
    return this.utilService.calcPercentage(this.auditResultDetail.Score,this.auditResultDetail.MaxScore);
  }

  humanDate(date:Date){
    return this.utilService.humanDate(date);
  }

  overdueActions():number{
    return Math.max(0,this.auditResultDetail.TotalOverdueActions);
  }

}
