
<div class="lg:px-5 px-2 my-2 ">
    <div *ngIf="question.QuestionTypeID!=6" class="border-b-[1px] border-solid border-grey-500 ">
        <div *ngIf="question.QuestionTypeID !=8" class="flex justify-between py-2 lg:flex-row flex-col ">
            <div *ngIf="!isCompliant()"
                class="bg-red-500 rounded-full text-standardwhite px-4 lg:hidden mb-3 text-center print-hidden">
                <span class="">Not Compliant</span>
            </div>
            <div class=" lg:w-3/5 w-full print-no-break">
                <div class="flex px-2 ">
                    <p class="text-green-700 mr-2 font-bold">Q</p>
                    <div>
                        <p class="font-bold">{{question.QuestionHeader}}</p>
                        <p class="">{{question.QuestionBody}}</p>
                    </div>
                </div>
                <div class="hidden print-block  font-bold -ml-5 -mt-6  min-h-10" [ngClass]="{'text-red-600':!isResolved(),'text-green-400':isResolved()}"><span *ngIf="!isCompliant()" class="material-symbols-outlined" >warning</span><span *ngIf="isCompliant()" ></span></div>
                <div class="flex mt-2 px-2 -print-mt-3">
                    <p class="text-green-400 mr-2 font-bold">A</p>
                    <p>{{question.QuestionResult}}</p>
       
                </div>

                <div class="mx-5 mt-3 p-2 border-solid border-[1px] border-grey-500 rounded-md bg-grey-100 px-2  print-remove-border  print-mt-1"
                    *ngIf="question.QuestionComments.length>0">
                    <p class="text-green-700 print-text-xs">Comments</p>
                    <p class="italic  print-text-xs">{{question.QuestionComments}}</p>
                </div>
            </div>
            <div class="flex flex-col "
                [ngClass]="{'justify-between':question.MaxScore>0,'justify-end':!isCompliant()}">
                <div *ngIf="!isCompliant()" class="bg-red-500 rounded-full text-standardwhite px-4 lg:block hidden">
                    <span class="text-sm">Not Compliant</span>
                </div>
                <div class="flex justify-end mt-4 print-hidden">
                    <div class="flex">
                        <span
                            [ngClass]="{'text-green-400 cursor-pointer':question.QuestionAttachments.length>0,'text-grey-500 cursor-not-allowed':question.QuestionAttachments.length<=0}"
                            class="material-symbols-outlined text-3xl align-bottom  self-center"
                            (click)="switchAttachmentPanel()">attach_file</span>
                        <span
                            [ngClass]="{'text-green-400 cursor-pointer':question.Photos.length>0,'text-grey-500 cursor-not-allowed':question.Photos.length<=0}"
                            class="material-symbols-outlined text-3xl align-bottom  self-center ml-2"
                            (click)="switchPhotoPanel()">photo_camera</span>
                        <span 
                            [ngClass]="{'text-green-400 cursor-pointer':allActionsResolved() && question.Actions.length > 0,'text-red-600 cursor-pointer':question.Actions.length>0 && !allActionsResolved(),'text-grey-500 cursor-not-allowed':question.Actions.length<=0}"
                            class="material-symbols-outlined text-3xl align-bottom  self-center ml-2"
                            (click)="switchActionPanel()">report</span>
                    </div>

                </div>
            </div>
        </div>
        <div *ngIf="question.QuestionTypeID == 8" class="px-2">
            <div class="flex">
                <p class="text-green-700 mr-2 font-bold">Q</p>
                <div>
                    <p class="font-bold">{{question.QuestionHeader}}</p>
                </div>
            </div>
            <div *ngIf=" question.QuestionResult.length>0 && question.QuestionResult != 'Question Not Answered'">
                <img [src]="question.QuestionResult" class="max-h-36" />
            </div>
            <div *ngIf=" question.QuestionResult=='Question Not Answered'">
                <p class="pl-6 pt-2">No signature provided.</p>
            </div>
        </div>
        <div *ngIf="question.Photos.length>0">
            <div [ngClass]="{'':expandedPhotoPanel,'hidden':!expandedPhotoPanel}"
                class="px-2 mx-5 border-[1px] border-solid border-grey-900 mb-2 rounded-md  print-block print-remove-border print-no-padding">
                <h4 class="mb-4 print-mb-1 mt-2 font-semibold text-green-700">Photos</h4>
                <div class="flex flex-wrap">
                    <div *ngFor="let p of question.Photos" class=" ml-2 mb-2 print-mb-1 print-ml-1 ">
                        <img [src]="photoSrc(p)" class="max-h-52 print-max-h-40" />
                    </div>
                </div>

            </div>
        </div>

        <div *ngIf="question.QuestionAttachments.length>0">
            <div [ngClass]="{'':expandedAttachmentPanel,'hidden':!expandedAttachmentPanel}"
                class="p-2 mx-5 border-[1px] border-solid border-grey-900 mb-2 rounded-md print-block print-remove-border print-no-padding">
                <h4 class="mb-4 print-mb-1 font-semibold text-green-700">Attachments</h4>

                <div *ngFor="let q of question.QuestionAttachments" class="mt-1 print-no-margin">
                    <p class="underline hover:text-green-700 cursor-pointer print-text-decoration-none"
                        (click)="downloadAttachment(q.GUID)">
                        {{filenameReduction(q.FileName)}}</p>
                </div>
            </div>
        </div>
        <div *ngIf="question.Actions.length>0">
            <div [ngClass]="{'':expandedActionPanel,'hidden':!expandedActionPanel}"
                class=" md:mx-5 print-mx-5  print-block print-no-break mb-3">
                <h4 class="mb-4 print-mb-1 mt-2 print-mt-1 print-mb-1 font-semibold text-green-700">Actions</h4>
                <table class="w-full table-auto bg-standardwhite border border-grey-900 p-2 print-text-xs overflow-x-auto">
                    <thead>
                        <tr class="border-b border-green-700">
                            <th class="text-left pl-2 md:w-1/8 "><span class="print-text-xs hidden md:block print-block">Status</span></th>
                            <th class="text-left">Action</th>
                            <th class="text-left md:w-1/8">Due/Resolved</th>
                            <th class="text-left  md:w-1/8 hidden md:block print-block">Assigned To</th>
                            <th class=" md:w-1/6 print-hidden"></th>
                            <!--<th class="hidden md:table-cell">Download</th>-->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let a of question.Actions">
                            <td class="text-left pr-2 pl-2">
                                <span *ngIf="a.Resolved" class="print-text-xs hidden md:block print-block">Resolved</span>
                                <span *ngIf="!a.Resolved" class="print-text-xs hidden md:block print-block">Open</span>
                                <span  *ngIf="a.Resolved" class="md:hidden material-symbols-outlined print-hidden">task_alt</span>
                                <span  *ngIf="!a.Resolved" class="md:hidden material-symbols-outlined print-hidden"></span>
                            </td>
                            <td class="text-left"><span class="print-text-xs hidden md:block  print-block">{{a.ActionDescription}}</span><span class="md:hidden print-hidden">{{stringReduction(a.ActionDescription,20)}}</span></td>
                            <td class="text-left" [ngClass]="{'text-red-600':isPastDate(a.ActionDeadline) && !a.Resolved}">
                                <span class="print-text-xs hidden md:block print-block" *ngIf="!a.Resolved">{{humanDate(a.ActionDeadline)}}</span><span class="print-text-xs hidden md:block print-block" *ngIf="a.Resolved">{{humanDate(a.ResolvedDate)}}</span>
                                <span class="print-text-xs md:hidden print-hidden" *ngIf="!a.Resolved">{{shortDate(a.ActionDeadline)}}</span><span class="print-text-xs md:hidden  print-hidden" *ngIf="a.Resolved">{{shortDate(a.ResolvedDate)}}</span>
                            </td>
                                <td class="text-left hidden md:table-cell print-table-cell">{{a.AssignedTo}}</td>
                            <!--<td class="p-2 text-center hidden md:table-cell"><button class="btn-primary" (click)="fetchAttachment(a)"><span class="material-symbols-outlined text-xl mr-1 align-bottom text-standardwhite self-center">download</span></button></td>-->
                            <td class="p-2 text-right print-hidden "><span class="underline hover:cursor-pointer hover:text-green-700 print-text-xs hidden md:block"
                                    (click)="detailAction(a.ActionID)">View Action</span><span (click)="detailAction(a.ActionID)" class="md:hidden material-symbols-outlined self-center">expand_circle_right</span></td>
                        </tr>
                    </tbody>
                </table>



                <!--<div *ngFor="let a of question.Actions" class="flex mb-3 print-mb-1">
                    <div class="flex w-full print-justify-start">
                        <strong class="flex-grow">{{a.ActionDescription}}</strong>
                        <p class="w-1/5" *ngIf="!a.Resolved" [ngClass]="{'text-red-600':isPastDate(a.ActionDeadline)}">
                            {{humanDate(a.ActionDeadline)}}</p>
                        <p class="w-1/5" *ngIf="a.Resolved">Resolved on {{humanDate(a.ResolvedDate)}}</p>
                        <div class="flex justify-end w-1/6  print-hidden">
                            <p class="underline hover:cursor-pointer hover:text-green-700"
                                (click)="detailAction(a.ActionID)">View Action</p>
                        </div>

                    </div>


                </div>-->
            </div>
        </div>

    </div>
    <div *ngIf="question.QuestionTypeID==6" class="border-t-4 mt-2 pt-2 border-solid border-grey-500">
        <strong>{{question.QuestionHeader}}</strong>
    </div>

</div>