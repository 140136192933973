<div class="container">
    <h1 class="lg:p-8 lg:pb-4 p-2" [ngClass]="{'hidden':!showList}">Audit Results</h1>
    <div class="lg:ml-8 p-2">
        <div [ngClass]="{'hidden':!showList}" class="flex  flex-col lg:mt-5 mt-2 px-2">
            <div class="w-full mb-6">
                <div *ngIf="showFilters"    [ngClass]="{'mb-5':showFilters}" >
                    <h3>Filters</h3>
                </div>
                <div [ngClass]="{'hidden':!showFilters}" class="flex justify-center mt-6">
                    <div class="w-full lg:px-8 px-2">
                        <div class="flex justify-between lg:px-4">
                            <div class="w-3/5">
                                <div class="">
                                    <strong class="">Audit</strong>
                                    <div class="pr-3">
                                        <select class="text-box" [(ngModel)]="selectedAuditDDL">
                                            <option *ngFor="let a of auditDDLList" [ngValue]="a">
                                                {{a.Description}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class=" mt-2">
                                    <strong class="w-2/5">Location</strong>
                                    <div class="pr-3">
                                        <select class="text-box " [(ngModel)]="selectedLocationDDL">
                                            <option *ngFor="let a of locations" [ngValue]="a">
                                                {{a.Description}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="ml-2 flex-grow">
                                <div>
                                    <label class="">
                                        <strong>Start Date:</strong>
                                        <input [owlDateTime]="dt" class="text-box " [(ngModel)]="startDate"
                                            [owlDateTimeTrigger]="dt" />
                                        <owl-date-time [pickerType]="'calendar'" #dt></owl-date-time>
                                    </label>
                                </div>
                                <div class="mt-2">
                                    <label>
                                        <strong>End Date:</strong>
                                        <input [owlDateTime]="dt2" class="text-box" [(ngModel)]="endDate"
                                            [owlDateTimeTrigger]="dt2" />
                                        <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="w-full flex justify-center">
                            <button class="btn-primary mt-7 text-lg w-full md:w3/5 lg:w-2/5"
                                (click)="refreshSearch()">Search</button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!showFilters">
                <div class=" lg:px-8 px-2">
                    <div class="primary-green-border w-full border-grey-500 "></div>
                </div>
                <div class="flex justify-center my-4"><button class="btn-inverse" (click)="unhideFilters()">Show Filters</button></div>
            </div>

            <div class=" lg:px-8 px-2 ">
                <div class="primary-green-border w-full border-grey-500 "></div>
            </div>
            <div class="mt-6 w-full">
                <div class="flex justify-between align-middle mb-5">
                    <h3 class="" #resultStart>Results</h3>
                    <button class="btn-inverse h-auto text-xs" (click)="exportSelection()">Export</button>
                </div>
                <div *ngFor="let a of auditResultList">
                    <app-audit-result [audit]="a"
                        (resetNewAction)="getAudit(a.AuditCompletionInfo.AuditResultID)"></app-audit-result>
                </div>
            </div>

        </div>
    </div>


    <div [ngClass]="{'hidden':showList}" class="mt-5">
        <div class="mx-12 mb-7 flex justify-between print-hidden">
            <button (click)="unhideList()" class="btn-inverse">Back to results</button>
            <div>
                <button class="btn-inverse h-auto text-xs mr-3" (click)="printAudit()">Print</button>
                <button class="btn-inverse h-auto text-xs" (click)="exportFullAudit()">Export</button>
            </div>
           
        </div>
        <div class="px-8 print-no-padding">
            <app-audit-result-detail [auditResultDetail]="auditResultDetail"></app-audit-result-detail>
        </div>

    </div>

</div>