import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { UserSettingsPermissionsResult } from 'src/app/core-module/models/UserManagement/user-settings-permissions-result';
import { MenuOption } from 'src/app/core-module/models/Utils/menu-option';
import { MenuOptionHolder } from 'src/app/core-module/models/Utils/menu-option-holder';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UserManagementService } from 'src/app/core-module/services/usermanagement.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  constructor(private userManagement: UserManagementService, private router: Router, private toast: ToastrService){
    this.buildProfileOptions();
    this.buildUsersOptions();
    this.buildGroupsOptions();
    this.buildLocationMenuOptions();
    this.buildAuditMenuOptions();

    this.userManagement.userSettingsPermissions().pipe(
      tap(res => {
        if (!res.IsError){
          this.permissions = res;
        }
      })
    ).subscribe();

  }

  public Profile:MenuOptionHolder = new MenuOptionHolder({'SectionName':'Profile'})
  public UsersMenu:MenuOptionHolder = new MenuOptionHolder({'SectionName':'Users'})
  public GroupsMenu:MenuOptionHolder = new MenuOptionHolder({'SectionName':'Groups'})
  public LocationsMenu:MenuOptionHolder = new MenuOptionHolder({'SectionName':'Locations'})
  public AuditsMenu:MenuOptionHolder = new MenuOptionHolder({'SectionName':'Audits'})
  public permissions: UserSettingsPermissionsResult = new UserSettingsPermissionsResult();

  manageLicenses(){
    this.userManagement.manageLicenses().pipe(
      tap(res => {
        if (res.length > 0){
          window.location.href = res;
        } else {
          this.toast.addToastr(new Toastr({Type: 3, Message: "An error occurred getting your billing information, please try again later."}));
        }
        
      })
    ).subscribe();
  }
  buildUsersOptions(){
    this.UsersMenu.Options.push(new MenuOption({'URL':'settings/users/newuser','MenuName':'New User'}));
    this.UsersMenu.Options.push(new MenuOption({'URL':'settings/users/usermanagement','MenuName':'User Details'}));
    this.UsersMenu.Options.push(new MenuOption({'URL':'settings/users/existing','MenuName':'User Permissions'}));
    this.UsersMenu.Options.push(new MenuOption({'URL':'settings/locations/assignlocations','MenuName':'User Locations'}));
    this.UsersMenu.Options.push(new MenuOption({'URL':'settings/users/useraudits','MenuName':'User Audits'}));
  }
  buildGroupsOptions(){
    this.GroupsMenu.Options.push(new MenuOption({'URL':'settings/users/newgroup','MenuName':'New Group'}));
    this.GroupsMenu.Options.push(new MenuOption({'URL':'settings/users/existinggroups','MenuName':'Group Permissions'}));
    this.GroupsMenu.Options.push(new MenuOption({'URL':'settings/users/groups','MenuName':'Group Assignment'}));
    this.GroupsMenu.Options.push(new MenuOption({'URL':'settings/locations/assigngrouplocations','MenuName':'Group Locations'}));
    this.GroupsMenu.Options.push(new MenuOption({'URL':'settings/users/groupaudits','MenuName':'Group Audits'}));
  }
  buildProfileOptions(){
    this.Profile.Options.push(new MenuOption({'URL':'settings/users/profile','MenuName':'Update Profile'}));
  }
  buildLocationMenuOptions(){
    this.LocationsMenu.Options.push(new MenuOption({'URL':'/settings/locations/newlocation','MenuName':'New Location'}));
    this.LocationsMenu.Options.push(new MenuOption({'URL':'/settings/locations/editlocation','MenuName':'Edit Locations'}));
    this.LocationsMenu.Options.push(new MenuOption({'URL':'/settings/locations/assignlocationaudits','MenuName':'Location Audits'}));

  }
  buildAuditMenuOptions(){
    this.AuditsMenu.Options.push(new MenuOption({'URL':'/settings/manage/new/audit','MenuName':'New Audit'}));
    this.AuditsMenu.Options.push(new MenuOption({'URL':'/settings/manage/edit','MenuName':'Edit Existing Audit'}));
    this.AuditsMenu.Options.push(new MenuOption({'URL':'/settings/manage/audit/locations','MenuName':'Audit Locations'}));
    this.AuditsMenu.Options.push(new MenuOption({'URL':'/settings/locations/assignuseraudits','MenuName':'Audit Users'}));
  }
}
