export class DDL {
    public ID:number = -1;
    public Description: string;
    public Seq: number;
    public Active: boolean = true;
    public DefaultSelection:boolean = false;
    public SelectedOption:boolean = false;
    public SecondaryEmit:any;
    public Bold:boolean = false;
    constructor(values: Object = {}){
        Object.assign(this, values);   
    }
}