import { TemplateBindingParseResult } from '@angular/compiler';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, EMPTY, tap } from 'rxjs';
import { BasicAuditResult } from 'src/app/core-module/models/Audits/AuditResults/basic-audit-result';
import { BasicAuditReturn } from 'src/app/core-module/models/Audits/AuditResults/basic-audit-return';
import { DDL } from 'src/app/core-module/models/ddl';
import { DDLResult } from 'src/app/core-module/models/ddl-result';
import { EditAuditLocationLocation } from 'src/app/core-module/models/Locations/edit-audit-location-location';
import { EditAuditLocationResult } from 'src/app/core-module/models/Locations/edit-audit-location-result';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { AuditService } from 'src/app/core-module/services/audit.service';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UserService } from 'src/app/core-module/services/user.service';
import { UserManagementService } from 'src/app/core-module/services/usermanagement.service';

@Component({
  selector: 'app-auditlocations',
  templateUrl: './auditlocations.component.html',
  styleUrl: './auditlocations.component.scss'
})
export class AuditlocationsComponent {

  public auditList: BasicAuditReturn[] = [];
  public displayAuditList:BasicAuditReturn[]=[];
  public selectedAudit: BasicAuditReturn = new BasicAuditReturn();
  public auditTypeList:DDL[]=[];
  public selectedAuditType:DDL = new DDL();

  public locationsList: EditAuditLocationResult = new EditAuditLocationResult();
  private _originalLocationsList: EditAuditLocationResult = new EditAuditLocationResult();

  public allPermissions: boolean = false;
  public isSaving: boolean = false;

  public locationsLoaded: boolean = false;

  public minLocationLevel: number = -1;


  constructor(private auditService: AuditService, private toastr: ToastrService, private UMS: UserManagementService, private router: Router) {
    this.getAuditList();
  }

  ngOnInit() { }

  getAuditList() {
    this.auditService.getAuditLocationsAuditList().pipe(
      tap(res => {
        if (res.IsError) {
          this.toastr.addToastr(new Toastr({ Message: "An error occurred, please try again later.", Type: 1 }))
        } else {
          this.fillAuditList(res);
        }
      }),
      catchError((err, n) => {
        this.toastr.addToastr(new Toastr({ Message: "An error occurred, please try again later.", Type: 1 }))
        return EMPTY;
      })
    ).subscribe()
  }
  fillAuditList(result: BasicAuditResult) {
    this.auditTypeList = [];

    this.auditList = [];
    if (result.Audits.length > 0) {
      result.Audits.forEach(e => {
        if(this.auditTypeList.findIndex(f=>{return f.ID == e.AuditType.ID}) == -1){
          this.auditTypeList.push(e.AuditType);
        }
        this.auditList.push(new BasicAuditReturn(e));
      });

      this.selectedAudit = this.auditList[0];
      this.loadLocations()
    }
    this.auditTypeList.sort((a,b) =>{ return a.Description.localeCompare(b.Description)});
    var allTypes:DDL = new DDL({ID:-1,Description:'All Audit Types'});
    this.auditTypeList.unshift(allTypes);
    this.displayAuditList = this.auditList;
  }
  updateAuditList(){
    this.displayAuditList=[];
    if(this.selectedAuditType.ID == -1){
      this.auditList.forEach(e => {
        this.displayAuditList.push(e)
      });
    }else{
      this.auditList.forEach(e => {
        if(e.AuditType.ID == this.selectedAuditType.ID){
          this.displayAuditList.push(e)
        }else{
          //e.Display = false;
        }
 
      });
    }
    this.selectedAudit = this.displayAuditList[0];
    this.loadLocations();
  }
  loadLocations() {
    this.locationsLoaded = false;
    this.UMS.getLocationAuditLocations(this.selectedAudit.AuditID).pipe(
      tap(res => {
        this.locationsList = new EditAuditLocationResult(res);
        this.findLowestLevel();
        this.arrangeLocations();
        this.locationsLoaded = true;
        this.confirmAllPermission()
        this._originalLocationsList = new EditAuditLocationResult(JSON.parse(JSON.stringify(this.locationsList)));
      }),
      catchError((err, n) => {
        this.toastr.addToastr(new Toastr({ Message: "An error occurred, please try again later.", Type: 1 }));
        return EMPTY;
      })
    ).subscribe();
  }
  updateAllPermissions() {
    this.locationsList.Locations.forEach(e => {
      e.AssignedToAudit = this.allPermissions;
    });
  }
  locationChanged(location: EditAuditLocationLocation) {
    this.updateChildren(location)
    if (this.allPermissions) {
      this.allPermissions = false;
    } else {
      this.confirmAllPermission();
    }
  }
  updateChildren(location: EditAuditLocationLocation) {
    this.locationsList.Locations.forEach(e => {
      if (e.ParentID == location.LocationID) {
        e.AssignedToAudit = location.AssignedToAudit;
        this.updateChildren(e);
      }
    })
  }
  findLowestLevel() {
    this.locationsList.Locations.forEach(e => {
      if (this.minLocationLevel == -1) {
        this.minLocationLevel = e.LocationLevelSeq
      } else {
        if (this.minLocationLevel > e.LocationLevelSeq) {
          this.minLocationLevel = e.LocationLevelSeq
        }
      }
    })
  }
  arrangeLocations() {
    this.locationsList.Locations.sort((a, b) => {
      if (a.LocationLevelSeq > b.LocationLevelSeq) {
        return 1
      }
      return 0;
      //return a.LocationName.localeCompare(b.LocationName);
    })
    var sortedLocations: EditAuditLocationLocation[] = [];
    this.locationsList.Locations.forEach(e => {
      if (sortedLocations.findIndex(f => { return f.LocationID == e.LocationID }) == -1) {
        if (sortedLocations.findIndex(g => { return g.LocationID == e.ParentID }) == -1) {
          e.TopLevel = true;
          sortedLocations.push(e);
        } else {
          var tempIndex: number = sortedLocations.findIndex(g => { return g.LocationID == e.ParentID });
          sortedLocations.splice(tempIndex + 1, 0, e);
        }
      }
    })
    this.locationsList.Locations = sortedLocations;
  }
  confirmAllPermission() {
    var allPermissionsTest = true; 
    this.locationsList.Locations.forEach(e => {
      if (e.AssignedToAudit == false) {
        allPermissionsTest = false;
      }
    })
    this.allPermissions = allPermissionsTest;
  }
  auditTypeComparison(a: DDL, b: DDL): boolean {
    if (a == undefined || b == undefined || a == null || b == null) {
      return false;
    }
    return a.ID == b.ID;
  }
  auditComparison(a: BasicAuditReturn, b: BasicAuditReturn): boolean {
    if (a == undefined || b == undefined || a == null || b == null) {
      return false;
    }
    return a.AuditID == b.AuditID;
  }
  goBack() { this.router.navigateByUrl("/settings"); };
  validateChanges() {
    this.isSaving = true;
    var changeOccured = false;
    this.locationsList.Locations.forEach(e => {
      if (this._originalLocationsList.Locations.findIndex(f => { return f.LocationID == e.LocationID }) == -1) {
        this.toastr.addToastr(new Toastr({ Message: "An error occurred, please try again later.", Type: 1 }));
        this.isSaving = false;
        return;
      } else {
        if (this._originalLocationsList.Locations.find(g => e.LocationID == g.LocationID)!.AssignedToAudit !=e.AssignedToAudit) { 
          changeOccured = true;
        }
      }

    })
    if(changeOccured){
      this.saveChanges();
    }else{
      this.toastr.addToastr(new Toastr({ Message: "No changes detected.", Type: 1 }))
      this.isSaving = false
      return;
    }
  }
  saveChanges() {
    this.UMS.saveLocationAuditLocations(this.locationsList).pipe(
      tap(res => {
        if(res.IsError){
          this.toastr.addToastr(new Toastr({ Message: "An error occurred, please try again later.", Type: 1 }));
          this.isSaving = false;
        }else{
          this._originalLocationsList = new EditAuditLocationResult(JSON.parse(JSON.stringify(this.locationsList)));
          this.toastr.addToastr(new Toastr({ Message: "Locations successfully updated.", Type: 2 }));
          this.isSaving = false;
        }
      }),
      catchError((err, n) => {
        this.toastr.addToastr(new Toastr({ Message: "An error occurred, please try again later.", Type: 1 }));
        this.isSaving = false;
        return EMPTY;
      })
    ).subscribe();

  }
  resetPage() {
    this.locationsList = new EditAuditLocationResult(JSON.parse(JSON.stringify(this._originalLocationsList)));
    this.confirmAllPermission();
  }
}
