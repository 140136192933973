<div class="container p-4">
    <div class="px-4 mt-2">
        <button class="btn-inverse py-1" (click)="goBack()">< Back</button>
    </div>
	
    <div class="border-solid border-2 border-grey-500 mb-1 mt-3 mx-4"></div>
	

    <h1  class="p-4">Audit Users</h1>
    <div class="mt-4 mx-4">
        <p class="text-2xl font-bold" >Select Audit</p>
        <select class="mt-2 mr-4 text-box xl:w-3/5" [(ngModel)]="selectedAuditType" [compareWith]="auditTypeComparison" (change)="updateAuditList()">
            <option *ngFor="let a of auditTypeList" [ngValue]="a" >{{a.Description}}</option>
        </select>
        <select class="mt-2 mr-4 text-box xl:w-3/5"  (change)="loadUsers()" [(ngModel)]="selectedAudit" [compareWith]="auditComparison">
            <option [disabled]="a.AuditID == -1" *ngFor="let a of displayAuditList" [ngValue]="a" >{{a.AuditName}}</option>
        </select>
    </div>
    <div class="lg:mx-6 mx-4 mt-6" *ngIf="usersLoaded">
        <table>
            <tbody>
                <tr class="border-b-offblack border-b-2 mb-2 py-1">
                    <td class="font-bold">All</td>
                    <td class="flex justify-center py-2 pl-3">
                        <app-toggle-switch [(checked)]="allPermissions" (checkedChange)="updateAllPermissions()"></app-toggle-switch>
                    </td>
                </tr>
                <tr *ngFor="let p of loadedUsers; let i=index">
                    <td >{{p.DisplayName}}</td>
                    <td class="flex justify-center py-2 pl-3">
                        <app-toggle-switch  [(checked)]="p.HasPermission" (checkedChange)="userUpdate(p)"></app-toggle-switch>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="mt-3">
            <button class="btn-primary mr-4" (click)="validateChanges()" [disabled]="isSaving">
                <span *ngIf="!isSaving">Save</span>
                <span *ngIf="isSaving">Saving...</span>
            </button>
            <button class="btn-warning" (click)="resetPage()" [disabled]="isSaving">Undo</button>
        </div>
    </div>
</div>