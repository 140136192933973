import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, EMPTY, tap } from 'rxjs';
import { BasicAuditResult } from 'src/app/core-module/models/Audits/AuditResults/basic-audit-result';
import { BasicAuditReturn } from 'src/app/core-module/models/Audits/AuditResults/basic-audit-return';
import { DDL } from 'src/app/core-module/models/ddl';
import { DDLResult } from 'src/app/core-module/models/ddl-result';
import { AuditUser } from 'src/app/core-module/models/UserManagement/audit-user';
import { AuditUserResult } from 'src/app/core-module/models/UserManagement/audit-user-result';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { AuditService } from 'src/app/core-module/services/audit.service';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UserManagementService } from 'src/app/core-module/services/usermanagement.service';

@Component({
  selector: 'app-auditusers',
  templateUrl: './auditusers.component.html',
  styleUrl: './auditusers.component.scss'
})
export class AuditusersComponent {
  public auditList: BasicAuditReturn[] = [];
  public displayAuditList: BasicAuditReturn[] = [];
  public selectedAudit: BasicAuditReturn = new BasicAuditReturn();
  public auditTypeList: DDL[] = [];
  public selectedAuditType: DDL = new DDL();

  public loadedUsers:AuditUser[] = [];
  private _originalUsers:AuditUser[]=[];
  public allPermissions: boolean = false;
  public isSaving: boolean = false;
  public usersLoaded: boolean = false;
  constructor(private auditService: AuditService, private toastr: ToastrService, private router: Router, private userManagementService: UserManagementService) {
    this.getAuditList();
  }
  getAuditList() {
    this.auditService.getAuditUsersAuditList().pipe(
      tap(res => {
        if (res.IsError) {
          this.toastr.addToastr(new Toastr({ Message: "An error occurred, please try again later.", Type: 1 }))
        } else {
          this.fillAuditList(res);
        }
      }),
      catchError((err, n) => {
        this.toastr.addToastr(new Toastr({ Message: "An error occurred, please try again later.", Type: 1 }))
        return EMPTY;
      })
    ).subscribe()
  }
  fillAuditList(result: BasicAuditResult) {
    this.auditTypeList = [];

    this.auditList = [];
    if (result.Audits.length > 0) {
      result.Audits.forEach(e => {
        if (this.auditTypeList.findIndex(f => { return f.ID == e.AuditType.ID }) == -1) {
          this.auditTypeList.push(e.AuditType);
        }
        this.auditList.push(new BasicAuditReturn(e));
      });

      this.selectedAudit = this.auditList[0];
      this.loadUsers()
    }
    this.auditTypeList.sort((a, b) => { return a.Description.localeCompare(b.Description) });
    var allTypes: DDL = new DDL({ ID: -1, Description: 'All Audit Types' });
    this.auditTypeList.unshift(allTypes);
    this.displayAuditList = this.auditList;
  }
  auditTypeComparison(a: DDL, b: DDL): boolean {
    if (a == undefined || b == undefined || a == null || b == null) {
      return false;
    }
    return a.ID == b.ID;
  }
  auditComparison(a: BasicAuditReturn, b: BasicAuditReturn): boolean {
    if (a == undefined || b == undefined || a == null || b == null) {
      return false;
    }
    return a.AuditID == b.AuditID;
  }
  updateAuditList() {
    this.displayAuditList = [];
    if (this.selectedAuditType.ID == -1) {
      this.auditList.forEach(e => {
        this.displayAuditList.push(e)
      });
    } else {
      this.auditList.forEach(e => {
        if (e.AuditType.ID == this.selectedAuditType.ID) {
          this.displayAuditList.push(e)
        } else {
          //e.Display = false;
        }

      });
    }
    this.selectedAudit = this.displayAuditList[0];
    this.loadUsers();
  }
  updateAllPermissions() { 
    this.loadedUsers.forEach(e=>{
      e.HasPermission = this.allPermissions;
    })
  }
  loadUsers() {
    this.usersLoaded = false;
    if (this.selectedAudit.AuditID != -1) {
      this.userManagementService.getUserAuditUsers(this.selectedAudit.AuditID).pipe(
        tap(res => {
          if (res.IsError) {
            this.toastr.addToastr(new Toastr({ Message: "An error occurred, please try again later.", Type: 1 }))
          } else {
            this.buildUsers(res);
          }
        })).subscribe();
    }
  }
  buildUsers(auditUsers:AuditUserResult){
      this.loadedUsers = [];
      this._originalUsers = [];
      auditUsers.Users.forEach(e=>{
        this.loadedUsers.push(new AuditUser(e));
        this._originalUsers.push(new AuditUser(e));
      })      
      this.usersLoaded = true;
  }
  userUpdate(user:AuditUser){
    if (this.allPermissions) {
      this.allPermissions = false;
    } else {
      this.checkAllPermissions();
    }
  }
  checkAllPermissions(){
    var localAllPermissions:boolean = true;
    this.loadedUsers.forEach(e=>{
      if(!e.HasPermission){
        localAllPermissions = false;
      }
    })
    this.allPermissions = localAllPermissions;
  }
  validateChanges() {
    this.isSaving = true;
    var changeOccured = false;
    this.loadedUsers.forEach(e => {
      if (this._originalUsers.findIndex(f => { return f.UserID == e.UserID }) == -1) {
        this.toastr.addToastr(new Toastr({ Message: "An error occurred, please try again later.", Type: 1 }));
        this.isSaving = false;
        return;
      } else {
        if (this._originalUsers.find(g => e.UserID == g.UserID)!.HasPermission !=e.HasPermission) { 
          changeOccured = true;
        }
      }

    })
    if(changeOccured){
      this.saveChanges();
    }else{
      this.toastr.addToastr(new Toastr({ Message: "No changes detected.", Type: 1 }))
      this.isSaving = false
      return;
    }
   }
   saveChanges(){
    this.userManagementService.saveUserAuditUsers(this.loadedUsers).pipe(
      tap(res => {
        if (res.IsError) {
          this.toastr.addToastr(new Toastr({ Message: "An error occurred, please try again later.", Type: 1 }));
          this.isSaving=false;
        } else {
          this.toastr.addToastr(new Toastr({ Message: "User changes saved successfully.", Type: 2 }));
          this.moveOriginalToCurrent();
          this.isSaving=false;
        }
      })).subscribe();
   }
   moveOriginalToCurrent(){
    this._originalUsers = [];
    this.loadedUsers.forEach(e=>{
      this._originalUsers.push(new AuditUser(e));
    })

   }
  resetPage() {
    this.loadedUsers = []
    this._originalUsers.forEach(e=>{
      this.loadedUsers.push(new AuditUser(e));
    });
    this.checkAllPermissions();
   }
  goBack() { this.router.navigateByUrl("/settings"); };
}
