<div class="hover:cursor-pointer">
    <div class="border-2 border-offblack border-solid my-2 p-4 rounded-md" [ngClass]="{'border-red-600':audit.AuditCompletionInfo.TotalOverdueActions>0,'border-yellow-300':audit.AuditCompletionInfo.TotalOverdueActions<=0 && audit.AuditCompletionInfo.TotalOutstandingActions>0}" (click)="selectAudit()">
        <div class="flex justify-between">
            <h5 class="mr-2">{{audit.AuditName}}</h5>
            <div class="flex flex-col">
                <p class="font-bold text-right">{{formattedSubmissionDate()}}</p>
                <p class="text-right">{{audit.AuditCompletionInfo.LocationName}}</p>
            </div>
           
        </div>
        <div *ngIf="audit.AuditInfo.ReferenceAnswer.length>0">
            <p>{{audit.AuditInfo.ReferenceQuestion}}: <strong>{{audit.AuditInfo.ReferenceAnswer}}</strong></p>
        </div>
        <div class="mt-2">
            <p>Outstanding Actions: <strong >{{audit.AuditCompletionInfo.TotalOutstandingActions}}</strong> <span class="" *ngIf="audit.AuditCompletionInfo.TotalOverdueActions>0"> <strong class="text-red-600 ml-2">({{audit.AuditCompletionInfo.TotalOverdueActions}} overdue)</strong> </span></p>            
            <p>Score: <strong *ngIf="audit.AuditCompletionInfo.MaxScore>0">{{audit.AuditCompletionInfo.Score}}/{{audit.AuditCompletionInfo.MaxScore}} ({{calcPercentage()}}%)</strong><strong  *ngIf="audit.AuditCompletionInfo.MaxScore<=0">N/A</strong></p>
        </div>
    </div>
</div>